import React, { useContext, useState } from 'react';

import { Box, Button, Modal, Typography, Stack } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { Sequence } from 'src/types/Sequence';
import { useAuth0 } from '@auth0/auth0-react';
import toast from 'react-hot-toast';
import { deleteSequence } from 'src/api/SequencesQueries';
import { SeqContext } from 'src/contexts/SeqContext';

const style = {
  position: 'absolute',
  top: '50%',
  left: '55%',
  transform: 'translate(-50%, -50%)',
  width: '50%',
  height: '65%',
  bgcolor: 'background.paper',
  border: '1px solid #000',
  boxShadow: 24,
  borderRadius: '16px',
  padding: 4,
};

interface DeleteConfirmationProps {
  open: boolean;
  onClose: () => void;
  sequence: Sequence;
}

const DeleteConfirmation: React.FC<DeleteConfirmationProps> = ({ open, onClose, sequence }) => {
  const [loading, setLoading] = useState(false);
  const { sequences, setSequences } = useContext(SeqContext);
  const { getAccessTokenSilently } = useAuth0();

  const handleDel = async (): Promise<void> => {
    setLoading(true);
    const accessToken = await getAccessTokenSilently();
    if (sequence.id) {
      const result: Sequence | undefined = await deleteSequence(accessToken, sequence.id);
      if (!result) {
        // Should only return data if response.ok (i.e. query was successful)
        toast.error('Error deleting sequence');
      } else {
        toast.success('Sequence deleted successfully');
        if (result.id) {
          setSequences(sequences.filter((seq: Sequence) => seq.id !== result.id));
        }
        onClose();
      }
    } else {
      toast.error('Sequence ID is undefined');
    }
    setLoading(false);
  };

  const handleClose = (): void => {
    onClose();
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={style}>
        <Stack direction="column" spacing={3} alignItems="center">
          <Typography variant="h4">Sequence Deletion Confirmation</Typography>
          <Typography variant="body1">Are you sure you want to delete this sequence?</Typography>
          <Typography sx={{ fontSize: 16, width: '90%', margin: 2, height: '90%', overflowX: 'auto' }}>
            <pre>Name: {sequence.name}</pre>
            <pre>Type: {sequence.type}</pre>
            <pre>Sequence: {sequence.seq}</pre>
            <pre>Annotations: {JSON.stringify(sequence.annotations)}</pre>
          </Typography>

          <Box sx={{ display: 'flex', direction: 'row' }}>
            <LoadingButton
              loading={loading}
              onClick={() => void handleDel()}
              variant="contained"
              color="error"
              sx={{ m: 2 }}
            >
              Yes
            </LoadingButton>
            <Button onClick={handleClose} variant="contained" sx={{ m: 2 }}>
              No
            </Button>
          </Box>
        </Stack>
      </Box>
    </Modal>
  );
};

export default DeleteConfirmation;
