import React from 'react';
import { Modal, Box, Typography, Button, Paper } from '@mui/material';

interface GeneralConfirmationProps {
  open: boolean;
  title: string;
  message: string;
  onConfirm: () => void | Promise<void>;
  onClose: () => void;
  confirmText?: string;
  cancelText?: string;
  isLoading?: boolean;
  severity?: 'warning' | 'error' | 'info' | 'success';
}

const GeneralConfirmation: React.FC<GeneralConfirmationProps> = ({
  open,
  title,
  message,
  onConfirm,
  onClose,
  confirmText = 'Confirm',
  cancelText = 'Cancel',
  isLoading = false,
  severity = 'warning',
}) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="confirmation-dialog-title"
      aria-describedby="confirmation-dialog-description"
    >
      <Paper
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          p: 4,
          outline: 'none',
        }}
        role="dialog"
        aria-modal="true"
      >
        <Typography id="confirmation-dialog-title" variant="h6" component="h2" gutterBottom>
          {title}
        </Typography>
        <Typography id="confirmation-dialog-description" sx={{ mt: 2, mb: 4 }}>
          {message}
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'space-evenly', gap: 2 }}>
          <Button onClick={onClose} variant="contained" color="primary" disabled={isLoading} aria-label={cancelText}>
            {cancelText}
          </Button>
          <Button
            onClick={() => void onConfirm()}
            variant="contained"
            color={severity === 'error' ? 'error' : 'success'}
            disabled={isLoading}
            aria-label={confirmText}
          >
            {confirmText}
          </Button>
        </Box>
      </Paper>
    </Modal>
  );
};

export default GeneralConfirmation;
