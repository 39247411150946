import React from 'react';
import { TableRow, TableCell, TextField, IconButton, Box } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import PaginatedTable from 'src/components/PaginatedTable';
import { DisplayRow } from './types';

const columns = [
  { id: 'id', label: 'ID' },
  { id: 'resource', label: 'Resource' },
  { id: 'header', label: 'Header' },
  { id: 'preview', label: 'Preview' },
  { id: 'value', label: 'Value' },
  { id: 'actions', label: 'Actions' },
];

interface ResourceKeysTableProps {
  rows: DisplayRow[];
  editingKey: string | null;
  editValue: string;
  visibleValues: Set<string>;
  onEditClick: (row: DisplayRow) => void;
  onEditSave: () => void;
  onEditValueChange: (value: string) => void;
  onToggleVisibility: (keyId: string) => void;
  onDeleteKey: (resourceKeyId: string) => void;
  isLoading?: boolean;
}

const ResourceKeysTable: React.FC<ResourceKeysTableProps> = ({
  rows,
  editingKey,
  editValue,
  visibleValues,
  onEditClick,
  onEditSave,
  onEditValueChange,
  onToggleVisibility,
  onDeleteKey,
  isLoading = false,
}) => {
  const renderRow = (row: DisplayRow) => (
    <TableRow key={row.keyId}>
      <TableCell>{row.resourceKeyId || ''}</TableCell>
      <TableCell>{row.resourceName}</TableCell>
      <TableCell>{row.header}</TableCell>
      <TableCell>{row.preview}</TableCell>
      <TableCell>
        {editingKey === row.resourceKeyId || editingKey === row.keyId ? (
          <TextField
            value={editValue}
            onChange={(e) => onEditValueChange(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault();
                onEditSave();
              }
            }}
            size="small"
            fullWidth
            type={visibleValues.has(row.keyId) ? 'text' : 'password'}
          />
        ) : (
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <Box
              component="span"
              sx={{
                fontFamily: 'monospace',
                whiteSpace: 'nowrap',
                display: 'inline-block',
                width: '16ch',
                overflowX: 'auto',
                scrollbarWidth: 'thin',
                '&::-webkit-scrollbar': {
                  height: '4px',
                },
              }}
            >
              {row.value ? (visibleValues.has(row.keyId) ? row.value : '••••••••') : ''}
            </Box>
          </Box>
        )}
      </TableCell>
      <TableCell>
        <Box sx={{ display: 'flex', gap: 1 }}>
          {row.value ? (
            <>
              <IconButton
                onClick={() => onToggleVisibility(row.keyId)}
                size="small"
                aria-label={visibleValues.has(row.keyId) ? 'Hide value' : 'Show value'}
              >
                {visibleValues.has(row.keyId) ? <VisibilityOffIcon /> : <VisibilityIcon />}
              </IconButton>
              <IconButton onClick={() => onEditClick(row)} size="small" aria-label="Edit value">
                <EditIcon />
              </IconButton>
              <IconButton
                onClick={() => row.resourceKeyId && onDeleteKey(row.resourceKeyId)}
                size="small"
                aria-label="Delete key"
              >
                <DeleteIcon />
              </IconButton>
            </>
          ) : (
            <IconButton onClick={() => onEditClick(row)} size="small" aria-label="Edit value">
              <EditIcon />
            </IconButton>
          )}
        </Box>
      </TableCell>
    </TableRow>
  );

  return isLoading ? (
    <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>Loading...</Box>
  ) : (
    <PaginatedTable columns={columns} data={rows} renderRow={renderRow} initialLimit={5} />
  );
};

export default ResourceKeysTable;
