import { FC, useState } from 'react';
import { createContext, Dispatch, SetStateAction } from 'react';
import { Sequence } from 'src/types/Sequence';

interface SeqContextType {
  sequences: Sequence[];
  setSequences: Dispatch<SetStateAction<Sequence[]>>;
}

export const SeqContext = createContext<SeqContextType>({} as SeqContextType);

const SeqProvider: FC = ({ children }) => {
  const [sequences, setSequences] = useState<Sequence[]>([]);

  return <SeqContext.Provider value={{ sequences, setSequences }}>{children}</SeqContext.Provider>;
};

export default SeqProvider;
