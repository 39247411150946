import { Organization } from './Organization';
import { Key } from './Key';
// Empty for now. We don't have non-default resources yet.
export enum ResourceName {
  SEQUENCES = 'Sequences',
}

export interface Resource {
  id: string;
  name: ResourceName;
  keys: Key[];
  organizations: Organization[];
  organizationId: string[];
}
