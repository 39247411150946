import React, { useState, useEffect, useMemo } from 'react';
import { Box, FormControl, InputLabel, Select, MenuItem, SelectChangeEvent } from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';

import PageHeader from './PageHeader';
import ResourceKeysTable from './ResourceKeysTable';
import GeneralConfirmation from 'src/components/Modals/GeneralConfirmation';

import { createResourceKey, getResourceKeys, updateResourceKey, deleteResourceKey } from 'src/api/ResourceKeyQueries';
import { getMe } from 'src/api/UserQueries';

import { ResourceKey } from 'src/types/ResourceKey';
import { DisplayRow } from './types';
import { User } from 'src/types/User';

const ResourceKeysPage: React.FC = () => {
  const { getAccessTokenSilently } = useAuth0();

  const [resources, setResources] = useState<User['organization']['enabledResources']>([]);
  const [selectedResource, setSelectedResource] = useState<string>('');
  const [resourceKeys, setResourceKeys] = useState<ResourceKey[]>([]);

  const [isLoading, setIsLoading] = useState(true);

  const [editingKey, setEditingKey] = useState<string | null>(null);
  const [editValue, setEditValue] = useState<string>('');
  const [visibleValues, setVisibleValues] = useState<Set<string>>(new Set());

  const [confirmDialog, setConfirmDialog] = useState<{
    open: boolean;
    title: string;
    message: string;
    onConfirm: () => Promise<void>;
    isLoading: boolean;
    severity?: 'warning' | 'error' | 'info' | 'success';
  }>({
    open: false,
    title: '',
    message: '',
    onConfirm: async () => {},
    isLoading: false,
  });

  // Fetch both resource keys and user data (which includes enabled resources)
  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const accessToken = await getAccessTokenSilently();
        const [keysResponse, userResponse] = await Promise.all([getResourceKeys(accessToken), getMe(accessToken)]);

        if (keysResponse) setResourceKeys(keysResponse);
        if (!userResponse) {
          setResources([]);
          return;
        }

        setResources(userResponse.organization.enabledResources);
      } catch (error) {
        console.error('Failed to fetch data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    void fetchData();
  }, [getAccessTokenSilently]);

  // Build table rows by combining Resource.keys with their matching ResourceKeys
  const tableRows = useMemo(() => {
    const rows: DisplayRow[] = [];
    resources.forEach((resource) => {
      resource.keys.forEach((key) => {
        // Find matching ResourceKey if one exists for this key
        const matchingResourceKey = resourceKeys.find((rk) => rk.keyId === key.id);
        rows.push({
          resourceId: resource.id,
          resourceName: String(resource.name),
          keyId: key.id,
          header: key.header,
          preview: key.preview,
          resourceKeyId: matchingResourceKey?.id,
          value: matchingResourceKey?.value,
        });
      });
    });
    return rows;
  }, [resources, resourceKeys]);

  const filteredRows = useMemo(() => {
    if (selectedResource) {
      return tableRows.filter((row) => row.resourceId === selectedResource);
    }
    return tableRows;
  }, [selectedResource, tableRows]);

  const handleResourceChange = (event: SelectChangeEvent) => {
    setSelectedResource(event.target.value);
    setVisibleValues(new Set());
    setEditingKey(null);
    setEditValue('');
  };

  const toggleValueVisibility = (keyId: string) => {
    setVisibleValues((prev) => {
      const next = new Set(prev);
      if (next.has(keyId)) {
        next.delete(keyId);
      } else {
        next.add(keyId);
      }
      return next;
    });
  };

  const handleDeleteKey = (resourceKeyId: string) => {
    if (!resourceKeyId) return;

    setConfirmDialog({
      open: true,
      title: 'Confirm Delete',
      message: 'Are you sure you want to delete this key?',
      severity: 'error',
      onConfirm: async () => {
        try {
          const accessToken = await getAccessTokenSilently();
          await deleteResourceKey(accessToken, resourceKeyId);
          setResourceKeys((keys) => keys.filter((k) => k.id !== resourceKeyId));
          setConfirmDialog((prev) => ({ ...prev, open: false }));
        } catch (error) {
          console.error('Failed to delete key:', error);
        }
      },
      isLoading: false,
    });
  };

  const handleEditClick = (row: DisplayRow) => {
    setEditingKey(row.resourceKeyId || row.keyId);
    setEditValue(row.value || '');
  };

  const handleEditSave = () => {
    if (!editingKey) return;

    setConfirmDialog({
      open: true,
      title: 'Confirm Edit',
      message: 'Are you sure you want to edit this key?',
      onConfirm: async () => {
        try {
          const accessToken = await getAccessTokenSilently();
          const row = tableRows.find((r) => r.resourceKeyId === editingKey || r.keyId === editingKey);
          if (!row) return;

          if (row.resourceKeyId) {
            const updatedKey = await updateResourceKey(accessToken, row.resourceKeyId, editValue);
            if (updatedKey) {
              setResourceKeys((keys) => keys.map((k) => (k.id === row.resourceKeyId ? updatedKey : k)));
            }
          } else {
            const newKey = await createResourceKey(accessToken, row.keyId, editValue);
            if (newKey) {
              setResourceKeys((keys) => [...keys, newKey]);
            }
          }

          setEditingKey(null);
          setEditValue('');
          setConfirmDialog((prev) => ({ ...prev, open: false }));
        } catch (error) {
          console.error('Failed to save key:', error);
        }
      },
      isLoading: false,
    });
  };

  return (
    <Box sx={{ p: 3 }}>
      <PageHeader />

      <Box sx={{ mt: 4, mb: 2, width: '40%' }}>
        <FormControl fullWidth disabled={isLoading}>
          <InputLabel>Resource</InputLabel>
          <Select
            value={selectedResource}
            label="Resource"
            onChange={handleResourceChange}
            aria-label="Select Resource"
          >
            <MenuItem value="">All Resources</MenuItem>
            {resources.map((resource) => (
              <MenuItem key={resource.id} value={resource.id}>
                {resource.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

      <ResourceKeysTable
        rows={filteredRows}
        editingKey={editingKey}
        editValue={editValue}
        visibleValues={visibleValues}
        onEditClick={handleEditClick}
        onEditSave={handleEditSave}
        onEditValueChange={setEditValue}
        onToggleVisibility={toggleValueVisibility}
        onDeleteKey={handleDeleteKey}
        isLoading={isLoading}
      />

      <GeneralConfirmation
        open={confirmDialog.open}
        title={confirmDialog.title}
        message={confirmDialog.message}
        severity={confirmDialog.severity}
        onConfirm={confirmDialog.onConfirm}
        onClose={() => {
          setConfirmDialog((prev) => ({ ...prev, open: false }));
          setEditingKey(null);
          setEditValue('');
        }}
        isLoading={confirmDialog.isLoading}
      />
    </Box>
  );
};

export default ResourceKeysPage;
