import { Helmet } from 'react-helmet-async';
import PageHeader from './PageHeader';
import PageTitleWrapper from 'src/components/PageTitleWrapper';
import { Grid, Container } from '@mui/material';
import SequencesTable from './SequencesTable';
import { useContext, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { getAllSequences } from 'src/api/SequencesQueries';
import { SeqContext } from 'src/contexts/SeqContext';

function AllSequences() {
  const { getAccessTokenSilently } = useAuth0();

  const { sequences, setSequences } = useContext(SeqContext);

  const fetchData = async () => {
    const accessToken = await getAccessTokenSilently();
    const response = await getAllSequences(accessToken);
    if (response) {
      setSequences(response);
    }
  };

  useEffect(() => {
    void fetchData();
  }, []);

  return (
    <>
      <Helmet>
        <title>Sequences</title>
      </Helmet>
      <PageTitleWrapper>
        <PageHeader />
      </PageTitleWrapper>
      <Container maxWidth="lg">
        <Grid container direction="row" justifyContent="center" alignItems="stretch" spacing={3}>
          <Grid item xs={12}>
            <SequencesTable sequences={sequences} />
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

export default AllSequences;
