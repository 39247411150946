import { FC, useState, createContext, useEffect, useMemo } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { User } from 'src/types/User';
import { getMe } from 'src/api/UserQueries';
import { RoleName } from 'src/types/Role';
import { ResourceName } from 'src/types/Resource';

type SidebarContextProps = {
  sidebarToggle: boolean;
  toggleSidebar: () => void;
  closeSidebar: () => void;
  user: User | null;
  setUser: (user: User | null) => void;
  roles: RoleName[];
  enabledResources: ResourceName[];
};

export const SidebarContext = createContext<SidebarContextProps>({} as SidebarContextProps);

export const SidebarProvider: FC = ({ children }) => {
  const [sidebarToggle, setSidebarToggle] = useState(false);
  const [user, setUser] = useState<User | null>(null);
  const { getAccessTokenSilently, isAuthenticated, isLoading } = useAuth0();

  const toggleSidebar = () => {
    setSidebarToggle(!sidebarToggle);
  };

  const closeSidebar = () => {
    setSidebarToggle(false);
  };

  const fetchUser = async () => {
    const accessToken = await getAccessTokenSilently();
    const userResponse = await getMe(accessToken);
    if (userResponse) {
      setUser(userResponse);
    }
  };

  useEffect(() => {
    if (!isLoading && isAuthenticated) {
      void fetchUser();
    }
  }, [isLoading, isAuthenticated]);

  const roles = useMemo(() => {
    if (!user) return [];
    return user.roles.map((role) => role.name as RoleName);
  }, [user]);

  const enabledResources = useMemo(() => {
    if (!user) return [];
    return user.organization.enabledResources.map((resource) => resource.name as ResourceName);
  }, [user]);

  return (
    <SidebarContext.Provider
      value={{
        sidebarToggle,
        toggleSidebar,
        closeSidebar,
        user,
        setUser,
        roles,
        enabledResources,
      }}
    >
      {children}
    </SidebarContext.Provider>
  );
};
