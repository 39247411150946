import { Helmet } from 'react-helmet-async';
import PageHeader from './PageHeader';
import PageTitleWrapper from 'src/components/PageTitleWrapper';
import {
  Grid,
  Container,
  Stack,
  TextField,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Typography,
} from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import LogsTable from './LogsTable';
import { useAuth0 } from '@auth0/auth0-react';
import { ActivityLog } from 'src/types/ActivityLogs';
import { getActivityLogs } from 'src/api/ActivityLogsQueries';
import { getAllUsers } from 'src/api/UserQueries';

function ActivityLogsPage() {
  const { getAccessTokenSilently } = useAuth0();

  const [logs, setLogs] = useState<ActivityLog[]>([]);
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState<string>(new Date().toISOString().split('T')[0]);
  const [endDate, setEndDate] = useState<string>(new Date().toISOString().split('T')[0]);
  const [allUsers, setAllUsers] = useState<string[]>([]);
  const [selectedUser, setSelectedUser] = useState<string>('All');

  const fetchLogs = async () => {
    const accessToken = await getAccessTokenSilently();
    const startDateWithoutTimeZone = new Date(startDate.split('T')[0]);
    const endDateWithoutTimeZone = new Date(endDate.split('T')[0]);
    // Add one day to the end date to include the end date in the search
    endDateWithoutTimeZone.setDate(endDateWithoutTimeZone.getDate() + 1);
    const responseLogs = await getActivityLogs(accessToken, startDateWithoutTimeZone, endDateWithoutTimeZone);
    if (responseLogs) {
      setLogs(responseLogs);
    }
  };

  const fetchUsers = async () => {
    const accessToken = await getAccessTokenSilently();
    const responseUsers = await getAllUsers(accessToken);
    if (responseUsers) {
      setAllUsers(responseUsers.map((user) => user.email));
    }
  };

  const fetchLogsAndUsers = async () => {
    setLoading(true);
    await fetchLogs();
    await fetchUsers();
    setLoading(false);
  };

  useEffect(() => {
    void fetchLogsAndUsers();
  }, [startDate, endDate]);

  const filteredLogs = useMemo(() => {
    if (selectedUser === 'All') {
      return logs;
    }
    return logs.filter((log) => log.user.email === selectedUser);
  }, [logs, selectedUser]);

  return (
    <>
      <Helmet>
        <title>Activity logs</title>
      </Helmet>
      <PageTitleWrapper>
        <PageHeader />
      </PageTitleWrapper>
      <Container maxWidth="lg">
        <Grid container direction="row" justifyContent="center" alignItems="stretch" spacing={3}>
          <Grid item xs={12}>
            <Stack direction="row" spacing={3} sx={{ mb: 1 }}>
              <FormControl size="small" disabled={loading}>
                <TextField
                  size="small"
                  label="Start date"
                  type="date"
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                />
              </FormControl>
              <FormControl size="small" disabled={loading}>
                <TextField
                  size="small"
                  label="End date"
                  type="date"
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                />
              </FormControl>
              <FormControl size="small" disabled={loading}>
                <InputLabel>User</InputLabel>
                <Select
                  label="User"
                  sx={{ minWidth: 120 }}
                  value={selectedUser}
                  onChange={(e) => setSelectedUser(e.target.value)}
                >
                  {['All', ...allUsers].map((user, index) => (
                    <MenuItem key={index} value={user}>
                      {user}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Stack>
            <Typography variant="body1" sx={{ p: 2 }} textAlign="center">
              <b>Number of requests: {filteredLogs.length}</b>
            </Typography>
            <LogsTable logs={filteredLogs} />
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

export default ActivityLogsPage;
