import { FC, useState } from 'react';
import {
  Tooltip,
  Divider,
  Card,
  IconButton,
  TableCell,
  TableRow,
  Typography,
  TypographyProps,
  useTheme,
} from '@mui/material';

import { Sequence } from 'src/types/Sequence';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import VisibilityIcon from '@mui/icons-material/Visibility';
import SequenceViewer from 'src/pages/sequences/AllSequences/Modals/SequenceViewer';
import DeleteConfirmation from 'src/pages/sequences/AllSequences/Modals/SeqDeleteConfirmation';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import PaginatedTable from 'src/components/PaginatedTable';

const CellTypography: React.FC<TypographyProps> = (props) => {
  return (
    <Typography variant="body1" fontWeight="bold" color="text.primary" gutterBottom noWrap {...props}>
      {
        // eslint-disable-next-line react/prop-types
        props.children
      }
    </Typography>
  );
};

interface SequencesTableProps {
  className?: string;
  sequences: Sequence[];
}

const SequencesTable: FC<SequencesTableProps> = ({ sequences }) => {
  const [openSeq, setOpenSeq] = useState(false);
  const [viewingSequence, setViewingSequence] = useState<Sequence | null>(null);
  const [delConfirm, setDelConfirm] = useState(false);
  const [deletingSequence, setDeletingSequence] = useState<Sequence | null>(null);

  const handleSeqClickOpen = (sequence: Sequence) => {
    setViewingSequence(sequence);
    setOpenSeq(true);
  };

  const handleSeqClose = () => {
    setOpenSeq(false);
  };

  const handleDelClickOpen = (sequence: Sequence) => {
    setDeletingSequence(sequence);
    setDelConfirm(true);
  };

  const handleDelClose = () => {
    setDelConfirm(false);
  };

  const handleCopyToClipboard = (id: string) => {
    void navigator.clipboard.writeText(id);
  };

  const theme = useTheme();

  return (
    <Card>
      <Divider />
      <PaginatedTable
        columns={[
          { id: 'name', label: 'Name' },
          { id: 'id', label: 'ID' },
          { id: 'type', label: 'Type' },
          { id: 'seq', label: 'Details' },
          { id: 'actions', label: 'Actions' },
        ]}
        data={sequences}
        renderRow={(seq) => (
          <TableRow hover key={seq.id}>
            <TableCell>
              <CellTypography>{seq.name}</CellTypography>
            </TableCell>
            <TableCell>
              <CellTypography>
                {seq.id}
                {seq.id ? (
                  <Tooltip title="Copy Sequence ID" arrow placement="right">
                    <IconButton onClick={() => seq.id && handleCopyToClipboard(seq.id)} size="small" sx={{ ml: 1 }}>
                      <ContentCopyIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                ) : (
                  <></>
                )}
              </CellTypography>
            </TableCell>
            <TableCell>
              <CellTypography sx={{ textTransform: 'uppercase' }}>{seq.type}</CellTypography>
            </TableCell>
            <TableCell>
              <CellTypography sx={{ maxWidth: 300, textOverflow: 'ellipsis', textTransform: 'uppercase' }}>
                {seq.seq}
              </CellTypography>
            </TableCell>
            <TableCell align="right">
              <Tooltip title="View sequence" arrow>
                <IconButton
                  sx={{
                    '&:hover': { background: theme.colors.secondary.lighter },
                    color: theme.palette.secondary.main,
                  }}
                  color="inherit"
                  size="small"
                  onClick={() => handleSeqClickOpen(seq)}
                >
                  <VisibilityIcon fontSize="small" />
                </IconButton>
              </Tooltip>
              <Tooltip title="Delete sequence" arrow>
                <IconButton
                  sx={{ '&:hover': { background: theme.colors.error.lighter }, color: theme.palette.error.main }}
                  color="inherit"
                  size="small"
                  onClick={() => handleDelClickOpen(seq)}
                >
                  <DeleteTwoToneIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            </TableCell>
          </TableRow>
        )}
        initialLimit={5}
      />
      {viewingSequence && <SequenceViewer open={openSeq} onClose={handleSeqClose} sequence={viewingSequence} />}
      {deletingSequence && (
        <DeleteConfirmation open={delConfirm} onClose={handleDelClose} sequence={deletingSequence} />
      )}
    </Card>
  );
};

export default SequencesTable;
