import { useState } from 'react';

import { Typography, Button, Grid } from '@mui/material';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import SearchIcon from '@mui/icons-material/Search';

import SeqImporter from 'src/pages/sequences/AllSequences/Modals/ImportSeq';

function PageHeader() {
  const [openUploadSequenceModal, setOpenUploadSequenceModal] = useState(false);

  return (
    <Grid container justifyContent="space-between" alignItems="center">
      <Grid item>
        <Typography variant="h3" component="h3" gutterBottom>
          Sequences
        </Typography>
        <Typography variant="subtitle2">
          Manage and work with all of your sequences from different tools in one place.
        </Typography>
      </Grid>
      <Grid item>
        <Button
          onClick={() => setOpenUploadSequenceModal(true)}
          sx={{ mt: { xs: 2, md: 0 }, mb: 1, width: 200 }}
          variant="contained"
          startIcon={
            <>
              <FileUploadIcon fontSize="small" />|<SearchIcon fontSize="small" />
            </>
          }
        >
          Add Sequence
        </Button>
        <SeqImporter open={openUploadSequenceModal} onClose={() => setOpenUploadSequenceModal(false)} />
      </Grid>
    </Grid>
  );
}

export default PageHeader;
