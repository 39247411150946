import { apiFetch } from './APIFetch';
import { User } from '../types/User';

export const getMe = async (accessToken: string): Promise<User | null> => {
  return apiFetch<User>({
    path: '/users/me',
    method: 'GET',
    accessToken,
  });
};

export const getAllUsers = async (accessToken: string): Promise<User[] | null> => {
  return apiFetch<User[]>({
    path: '/users/all-users',
    method: 'GET',
    accessToken,
  });
};

export const createUser = async (
  accessToken: string,
  email: string,
  password: string,
  organizationId: string,
  roleIds: string[],
): Promise<User | null> => {
  return apiFetch<User, { email: string; password: string; organizationId: string; roleIds: string[] }>({
    path: '/users/',
    method: 'POST',
    accessToken,
    body: {
      email,
      password,
      organizationId,
      roleIds,
    },
  });
};

export const createUserInOrganization = async (
  accessToken: string,
  email: string,
  password: string,
  roleIds: string[],
): Promise<User | null> => {
  return apiFetch<User, { email: string; password: string; roleIds: string[] }>({
    path: '/users/create-in-organization',
    method: 'POST',
    accessToken,
    body: {
      email,
      password,
      roleIds,
    },
  });
};

export const deleteUser = async (accessToken: string, id: string): Promise<User | null> => {
  return apiFetch<User>({
    path: `/users/${id}`,
    method: 'DELETE',
    accessToken,
  });
};

export const refreshToken = async (accessToken: string): Promise<User | null> => {
  return apiFetch<User>({
    path: '/users/token/refresh',
    method: 'POST',
    accessToken,
  });
};
