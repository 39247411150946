import { RouteObject } from 'react-router';
import BaseLayout from 'src/layouts/BaseLayout';
import Status404 from './pages/errors/Status404';
import Login from './pages/auth/Login';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import SidebarLayout from './layouts/SidebarLayout';
import Home from './pages/home';
import Users from './pages/superAdmin/Users';
import AllSequences from './pages/sequences/AllSequences';
import SeqProvider from './contexts/SeqContext';
import Organizations from './pages/superAdmin/Organizations';
import MyResources from './pages/user/MyResources';
import ActivityLogs from './pages/superAdmin/ActivityLogs';
import Profile from './pages/user/Profile';

const ProtectedLoginPage = withAuthenticationRequired(Login);
const ProtectedHomePage = withAuthenticationRequired(Home);
const ProtectedUsersPage = withAuthenticationRequired(Users);
const ProtectedOrganizationsPage = withAuthenticationRequired(Organizations);
const ProtectedMyResourcesPage = withAuthenticationRequired(MyResources);
const ProtectedActivityLogsPage = withAuthenticationRequired(ActivityLogs);
const ProtectedProfilePage = withAuthenticationRequired(Profile);

const routes: RouteObject[] = [
  {
    path: '',
    element: <BaseLayout />,
    children: [
      {
        path: '',
        element: <ProtectedLoginPage />,
      },
      {
        path: '*',
        element: <Status404 />,
      },
    ],
  },
  {
    path: 'home',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <ProtectedHomePage />,
      },
    ],
  },
  {
    path: 'data',
    element: <SidebarLayout />,
    children: [
      {
        path: 'sequences',
        element: (
          <SeqProvider>
            <AllSequences />
          </SeqProvider>
        ),
      },
    ],
  },
  {
    path: 'user',
    element: <SidebarLayout />,
    children: [
      {
        path: 'profile',
        element: <ProtectedProfilePage />,
      },
      {
        path: 'my-resources',
        element: <ProtectedMyResourcesPage />,
      },
    ],
  },
  {
    path: 'super-admin',
    element: <SidebarLayout />,
    children: [
      {
        path: 'users',
        element: <ProtectedUsersPage />,
      },
      {
        path: 'organizations',
        element: <ProtectedOrganizationsPage />,
      },
      {
        path: 'activity-logs',
        element: <ProtectedActivityLogsPage />,
      },
    ],
  },
];

export default routes;
