import { apiFetch } from './APIFetch';
import { ResourceKey } from '../types/ResourceKey';

export const getResourceKeys = async (accessToken: string): Promise<ResourceKey[] | null> => {
  return apiFetch({
    path: '/resource-keys',
    method: 'GET',
    accessToken,
  });
};

export const createResourceKey = async (
  accessToken: string,
  keyId: string,
  value: string,
): Promise<ResourceKey | null> => {
  return apiFetch({
    path: '/resource-keys',
    method: 'POST',
    accessToken,
    body: { keyId, value },
  });
};

export const updateResourceKey = async (
  accessToken: string,
  keyId: string,
  value: string,
): Promise<ResourceKey | null> => {
  return apiFetch({
    path: `/resource-keys/${keyId}`,
    method: 'PATCH',
    accessToken,
    body: { value },
  });
};

export const deleteResourceKey = async (accessToken: string, keyId: string): Promise<null> => {
  return apiFetch({
    path: `/resource-keys/${keyId}`,
    method: 'DELETE',
    accessToken,
  });
};
