import { apiFetch } from './APIFetch';
import { ActivityLog } from 'src/types/ActivityLogs';

export const getActivityLogs = async (
  accessToken: string,
  startDate: Date,
  endDate: Date,
): Promise<ActivityLog[] | null> => {
  return apiFetch<ActivityLog[]>({
    path: `/logs?startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}`,
    method: 'GET',
    accessToken,
  });
};
