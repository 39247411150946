import { FC, useState } from 'react';
import { Tooltip, IconButton, TableCell, TableRow, Typography, useTheme } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Label from 'src/components/Label';
import { ActivityLog } from 'src/types/ActivityLogs';
import PaginatedTable from 'src/components/PaginatedTable';
import ActivityLogDetails from './ActivityLogDetails';

interface ActivityLogsTableProps {
  className?: string;
  logs: ActivityLog[];
}

const getStatusCodeLabel = (status: number): JSX.Element => {
  switch (status) {
    case 200:
    case 201:
    case 202:
    case 204:
      return <Label color="success">{status}</Label>;
    case 400:
    case 401:
    case 403:
    case 500:
      return <Label color="error">{status}</Label>;
    default:
      return <Label>{status}</Label>;
  }
};

const ActivityLogsTable: FC<ActivityLogsTableProps> = ({ logs }) => {
  const theme = useTheme();

  const [open, setOpen] = useState(false);
  const [viewingLog, setViewingLog] = useState<ActivityLog | null>(null);

  const handleClickOpen = (log: ActivityLog) => {
    setViewingLog(log);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const columns = [
    { id: 'user', label: 'User' },
    { id: 'module', label: 'Module' },
    { id: 'operation', label: 'Operation' },
    { id: 'timestamp', label: 'Timestamp' },
    { id: 'status-code', label: 'Status Code' },
    { id: 'details', label: 'Details' },
  ];

  const renderRow = (log: ActivityLog) => (
    <TableRow hover key={log.id}>
      <TableCell>
        <Typography
          variant="body1"
          fontWeight="bold"
          color="text.primary"
          sx={{ maxWidth: 150, textOverflow: 'ellipsis' }}
          gutterBottom
          noWrap
        >
          {log.user.email}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography
          variant="body1"
          fontWeight="bold"
          color="text.primary"
          sx={{ textTransform: 'capitalize' }}
          gutterBottom
          noWrap
        >
          {log.module}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography
          variant="body1"
          fontWeight="bold"
          color="text.primary"
          sx={{ textTransform: 'capitalize' }}
          gutterBottom
          noWrap
        >
          {log.operation}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography
          variant="body1"
          fontWeight="bold"
          color="text.primary"
          sx={{ textTransform: 'capitalize' }}
          gutterBottom
          noWrap
        >
          {new Date(log.timestamp).toLocaleString()}
        </Typography>
      </TableCell>
      <TableCell align="center">
        <Typography
          variant="body1"
          fontWeight="bold"
          color="text.primary"
          sx={{ textTransform: 'capitalize' }}
          gutterBottom
          noWrap
        >
          {getStatusCodeLabel(log.statusCode)}
        </Typography>
      </TableCell>
      <TableCell align="center">
        <Tooltip title="View details" arrow>
          <IconButton
            sx={{
              '&:hover': { background: theme.colors.secondary.lighter },
              color: theme.palette.secondary.main,
            }}
            color="inherit"
            size="small"
            onClick={() => handleClickOpen(log)}
          >
            <VisibilityIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      </TableCell>
    </TableRow>
  );

  return (
    <>
      <PaginatedTable columns={columns} data={logs} renderRow={renderRow} />;
      {open && viewingLog && <ActivityLogDetails open={open} onClose={handleClose} log={viewingLog} />}
    </>
  );
};

export default ActivityLogsTable;
