import React from 'react';
import { Grid, Typography } from '@mui/material';

const PageHeader: React.FC = () => {
  return (
    <Grid container justifyContent="space-between" alignItems="center">
      <Grid item>
        <Typography variant="h3" component="h3" gutterBottom>
          Resource Keys
        </Typography>
        <Typography variant="subtitle2">
          Create, edit, or delete keys associated with your organization&apos;s enabled resources. Filter by resource
          type.
        </Typography>
      </Grid>
    </Grid>
  );
};

export default PageHeader;
