import { Sequence } from 'src/types/Sequence';

export const getAllSequences = async (accessToken: string): Promise<Sequence[] | undefined> => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/sequences`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    const data = await response.json();
    if (response.ok) {
      return data;
    } else {
      const message = data.message;
      console.error(message);
      return undefined;
    }
  } catch (e) {
    console.error(e);
    return undefined;
  }
};

export const createSequence = async (accessToken: string, sequence: Sequence): Promise<Sequence | undefined> => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/sequences`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json; charset=UTF-8',
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify(sequence),
    });
    const data = await response.json();
    if (response.ok) {
      return data;
    } else {
      const message = data.message;
      console.error(message);
      return undefined;
    }
  } catch (e) {
    console.error(e);
    return undefined;
  }
};

export const updateSequence = async (
  accessToken: string,
  sequenceId: string,
  sequence: Partial<Sequence>,
): Promise<Sequence | undefined> => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/sequences/${sequenceId}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json; charset=UTF-8',
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify(sequence),
    });
    const data = await response.json();
    if (response.ok) {
      return data;
    } else {
      const message = data.message;
      console.error(message);
      return undefined;
    }
  } catch (e) {
    console.error(e);
    return undefined;
  }
};

export const deleteSequence = async (accessToken: string, sequenceId: string): Promise<Sequence | undefined> => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/sequences/${sequenceId}`, {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    const data = await response.json();
    if (response.ok) {
      return data;
    } else {
      const message = data.message;
      console.error(message);
      return undefined;
    }
  } catch (e) {
    console.error(e);
    return undefined;
  }
};
