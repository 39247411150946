import { Box, Link, Typography } from '@mui/material';

export const FOOTER_HEIGHT = '80px';

function Footer() {
  return (
    <Box
      sx={{ height: FOOTER_HEIGHT }}
      py={2}
      px={5}
      display={{ xs: 'block', md: 'flex' }}
      alignItems="center"
      textAlign={{ xs: 'center', md: 'left' }}
      justifyContent="space-between"
    >
      <Box>
        <Typography variant="subtitle1">&copy; 2025 - MPI Dashboard</Typography>
      </Box>
      <img height="30" width="300" src="/static/images/logo/lattice_svg.svg" />
      <Typography
        sx={{
          pt: { xs: 2, md: 0 },
        }}
        variant="subtitle1"
      >
        Crafted by{' '}
        <Link href="https://latticeautomation.com" target="_blank" rel="noopener noreferrer">
          Lattice Automation
        </Link>
      </Typography>
    </Box>
  );
}

export default Footer;
