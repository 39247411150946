import HomeIcon from '@mui/icons-material/Home';
import AllInclusiveIcon from '@mui/icons-material/AllInclusive';
import { RoleName } from 'src/types/Role';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { SvgIconTypeMap } from '@mui/material';
import { ResourceName } from 'src/types/Resource';
import { DataUsage } from '@mui/icons-material';

export interface MenuItemConfig {
  title: string;
  path: string;
  icon: OverridableComponent<SvgIconTypeMap<{}, 'svg'>>;
  requiredRoles?: RoleName[];
  requiredResources?: ResourceName[];
}

export interface MenuSectionConfig {
  title: string;
  requiredRoles?: RoleName[];
  requiredResources?: ResourceName[];
  items: MenuItemConfig[];
}

export const menuConfig: MenuSectionConfig[] = [
  {
    title: 'General',
    requiredRoles: [],
    requiredResources: [],
    items: [
      {
        title: 'Home',
        path: '/home',
        icon: HomeIcon,
        requiredRoles: [],
        requiredResources: [],
      },
    ],
  },
  {
    title: 'Data',
    requiredRoles: [],
    requiredResources: [],
    items: [
      {
        title: 'Sequences',
        path: '/data/sequences',
        icon: DataUsage,
        requiredRoles: [RoleName.USER],
        requiredResources: [ResourceName.SEQUENCES],
      },
    ],
  },
  {
    title: 'Super Admin',
    requiredRoles: [RoleName.SUPER_ADMIN],
    requiredResources: [],
    items: [
      {
        title: 'Organizations',
        path: '/super-admin/organizations',
        icon: AllInclusiveIcon,
        requiredRoles: [RoleName.SUPER_ADMIN],
        requiredResources: [],
      },
      {
        title: 'Users',
        path: '/super-admin/users',
        icon: AllInclusiveIcon,
        requiredRoles: [RoleName.SUPER_ADMIN],
        requiredResources: [],
      },
      {
        title: 'Activity Logs',
        path: '/super-admin/activity-logs',
        icon: AllInclusiveIcon,
        requiredRoles: [RoleName.SUPER_ADMIN],
        requiredResources: [],
      },
    ],
  },
];
