import React, { useState, useContext } from 'react';
import { Box, Card, CardContent, Typography, IconButton, Tooltip } from '@mui/material';
import { Visibility, VisibilityOff, Refresh, ContentCopy } from '@mui/icons-material';
import { useAuth0 } from '@auth0/auth0-react';

import { refreshToken } from 'src/api/UserQueries';
import { SidebarContext } from 'src/contexts/SidebarContext';
import GeneralConfirmation from 'src/components/Modals/GeneralConfirmation';
import PageHeader from './PageHeader';

const ProfilePage: React.FC = () => {
  const { getAccessTokenSilently } = useAuth0();
  const { user, setUser } = useContext(SidebarContext);
  const [showToken, setShowToken] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState({
    open: false,
    isLoading: false,
  });

  const handleRefreshToken = async () => {
    setConfirmDialog((prev) => ({ ...prev, isLoading: true }));
    const accessToken = await getAccessTokenSilently();
    const updatedUser = await refreshToken(accessToken);
    if (updatedUser) {
      setUser(updatedUser);
    }
    setConfirmDialog({ open: false, isLoading: false });
  };

  const handleCopyToken = () => {
    if (user?.userToken) {
      void navigator.clipboard.writeText(user.userToken);
    }
  };

  if (!user) {
    return <Box sx={{ p: 3 }}>Failed to load profile</Box>;
  }

  return (
    <Box sx={{ p: 3 }}>
      <PageHeader />

      <Card sx={{ mt: 4 }}>
        <CardContent>
          <Typography variant="h6" gutterBottom>
            User Information
          </Typography>

          <Box sx={{ mt: 2 }}>
            <Typography>
              <strong>Email:</strong> {user.email}
            </Typography>
          </Box>

          <Box sx={{ mt: 2 }}>
            <Typography>
              <strong>Organization:</strong> {user.organization.name}
            </Typography>
          </Box>

          <Box sx={{ mt: 2 }}>
            <Typography>
              <strong>Roles:</strong> {user.roles.map((role) => role.name).join(', ')}
            </Typography>
          </Box>

          <Box sx={{ mt: 2, display: 'flex', alignItems: 'center', gap: 1 }}>
            <Typography>
              <strong>API Token:</strong>
            </Typography>
            <Typography sx={{ fontFamily: 'monospace', flex: 1 }}>
              {showToken ? user.userToken : '••••••••••••••••'}
            </Typography>
            <Tooltip title={showToken ? 'Hide Token' : 'Show Token'}>
              <IconButton onClick={() => setShowToken(!showToken)} size="small" color="primary">
                {showToken ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </Tooltip>
            <Tooltip title="Copy Token">
              <IconButton onClick={handleCopyToken} size="small" color="primary">
                <ContentCopy />
              </IconButton>
            </Tooltip>
            <Tooltip title="Refresh Token">
              <IconButton
                onClick={() => setConfirmDialog({ open: true, isLoading: false })}
                size="small"
                color="primary"
              >
                <Refresh />
              </IconButton>
            </Tooltip>
          </Box>
        </CardContent>
      </Card>

      <GeneralConfirmation
        open={confirmDialog.open}
        title="Refresh API Token"
        message="Are you sure you want to refresh your API token? The old token will no longer work."
        onConfirm={handleRefreshToken}
        onClose={() => setConfirmDialog({ open: false, isLoading: false })}
        isLoading={confirmDialog.isLoading}
        severity="warning"
      />
    </Box>
  );
};

export default ProfilePage;
