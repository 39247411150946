import React, { useContext, useState } from 'react';
import { Box, Button, Modal, Stack, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { parseFile } from 'seqparse';
import { Sequence } from 'src/types/Sequence';
import { useAuth0 } from '@auth0/auth0-react';
import toast from 'react-hot-toast';
import { Folder } from '@mui/icons-material';
import { createSequence } from 'src/api/SequencesQueries';
import { SeqContext } from 'src/contexts/SeqContext';

const style = {
  position: 'absolute',
  top: '50%',
  left: '55%',
  transform: 'translate(-50%, -50%)',
  width: '50%',
  height: '70%',
  minHeight: '650px',
  bgcolor: 'background.paper',
  border: '1px solid #000',
  boxShadow: 24,
  borderRadius: '16px',
  padding: 4,
};

interface SeqImporterProps {
  open: boolean;
  onClose: () => void;
}

const SeqImporter: React.FC<SeqImporterProps> = ({ open, onClose }) => {
  const { getAccessTokenSilently } = useAuth0();
  const { sequences, setSequences } = useContext(SeqContext);
  const [uploadedSequences, setUploadedSequences] = useState<Sequence[]>([]);

  const [currentSeqIndex, setCurrentSeqIndex] = useState<number>(0);
  const [currentSeq, setCurrentSeq] = useState<Sequence | null>(null);
  const [fileName, setFileName] = useState('');
  const [loading, setLoading] = useState(false);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setFileName(file.name);
      const reader = new FileReader();
      reader.onload = (e) => {
        const fileContent = e.target?.result as string;
        try {
          const parsedSequences = parseFile(fileContent);
          setUploadedSequences(parsedSequences);
          setCurrentSeqIndex(0);
          setCurrentSeq(parsedSequences[0]);
        } catch (error) {
          console.error('Error parsing sequences:', error);
          toast.error('Error parsing sequences');
        }
      };
      reader.readAsText(file);
    }
  };

  const handleFolderUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const files: FileList | null = event.target.files;

    if (files && files.length > 0) {
      const filesArr = Array.from(files);
      const goodFiles = filesArr.filter((file: File) => file.name.includes('.gb') || file.name.includes('.fasta'));
      const allSequences: Sequence[] = []; // Adjust type based on sequence structure
      try {
        for (const file of Array.from(goodFiles)) {
          const reader = new FileReader();
          const fileContent = await new Promise<string>((resolve, reject) => {
            reader.onload = (e) => {
              if (e.target?.result) {
                resolve(e.target.result as string);
              } else {
                reject(new Error('File could not be read'));
              }
            };
            reader.onerror = () => reject(reader.error);
            reader.readAsText(file);
          });

          const parsedSequences = parseFile(fileContent);
          allSequences.push(...parsedSequences);
        }
        setUploadedSequences(allSequences);
        setCurrentSeqIndex(0);
        setCurrentSeq(allSequences[0]);
      } catch (error) {
        console.error('Error parsing folder:', error);
        toast.error('Error parsing folder');
      }
    }
  };
  const handleNext = (): void => {
    if (currentSeqIndex < uploadedSequences.length - 1) {
      setCurrentSeqIndex(currentSeqIndex + 1);
      setCurrentSeq(uploadedSequences[currentSeqIndex + 1]);
    }
  };

  const handlePrevious = (): void => {
    if (currentSeqIndex > 0) {
      setCurrentSeqIndex(currentSeqIndex - 1);
      setCurrentSeq(uploadedSequences[currentSeqIndex - 1]);
    }
  };

  const handleClose = (): void => {
    setFileName('');
    setUploadedSequences([]);
    setCurrentSeqIndex(0);
    setCurrentSeq(null);
    onClose();
  };

  const handleImport = async () => {
    setLoading(true);
    try {
      const accessToken = await getAccessTokenSilently();
      let allSucceeded = true;
      const allSeqs: Sequence[] = [...sequences];
      for (const seq of uploadedSequences) {
        const result = await createSequence(accessToken, seq);
        if (result) {
          allSeqs.push(result);
        }
        if (!result) {
          allSucceeded = false;
          toast.error('Error importing sequence');
          break;
        }
      }
      if (allSucceeded) {
        setSequences(allSeqs);
        toast.success(`Sequence${uploadedSequences.length === 1 ? '' : 's'} imported successfully`);
      }
    } catch (error) {
      console.error('Error importing sequence:', error);
      toast.error(`Error importing sequence: ${error}`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={style}>
        <Stack direction="column" spacing={3} alignItems="center">
          <Typography variant="h4">Import Sequences</Typography>
          <Box sx={{ alignItems: 'left', width: '100%' }}>
            <Box sx={{ display: 'flex', direction: 'row', alignItems: 'center' }}>
              <input
                type="file"
                accept=".seq,.gb,.gbk,.genbank,.ape,.fasta,.fas,.fa,.dna,.sbd"
                onChange={handleFileChange}
                id="seqFilePicker"
                style={{ display: 'none' }}
              />
              <label htmlFor="seqFilePicker">
                <Button
                  component="span"
                  variant="contained"
                  sx={{ m: 1, width: 175 }}
                  startIcon={<FileUploadIcon fontSize="small" />}
                >
                  Browse File...
                </Button>
              </label>
              <input
                type="file"
                {...{ webkitdirectory: 'true' }}
                multiple
                onChange={(e) => void handleFolderUpload(e)}
                id="seqFolderPicker"
                style={{ display: 'none' }}
              />
              <label htmlFor="seqFolderPicker">
                <Button
                  component="span"
                  variant="contained"
                  sx={{ m: 1, width: 175 }}
                  startIcon={<Folder fontSize="small" />}
                >
                  Browse Folder...
                </Button>
              </label>
              {fileName && <Typography variant="body2">{fileName}</Typography>}
            </Box>
            <Typography sx={{ fontSize: 16, width: '90%', margin: 2, overflowX: 'auto' }}>
              <Box sx={{ height: '100%' }}>
                {currentSeq ? (
                  <>
                    <pre>Name: {currentSeq.name}</pre>
                    <pre>Type: {currentSeq.type}</pre>
                    <pre>Sequence: {currentSeq.seq}</pre>
                    <pre>Annotations: {JSON.stringify(currentSeq.annotations)}</pre>
                  </>
                ) : (
                  <Typography sx={{ mt: 15, textAlign: 'center' }}>No Sequences Uploaded</Typography>
                )}
              </Box>
            </Typography>
            <Box sx={{ mt: 20 }}>
              <Button onClick={handlePrevious} disabled={currentSeqIndex === 0} variant="contained">
                Previous
              </Button>
              <Button
                onClick={handleNext}
                disabled={currentSeqIndex >= uploadedSequences.length - 1}
                variant="contained"
              >
                Next
              </Button>
              <LoadingButton
                loading={loading}
                onClick={() => void handleImport()}
                disabled={uploadedSequences.length === 0}
                sx={{ ml: '15%' }}
                variant="contained"
                color="success"
              >
                {uploadedSequences.length < 2 ? 'Import' : 'Import All'}
              </LoadingButton>
              <Button onClick={handleClose} variant="contained" color="error" sx={{ ml: '15%' }}>
                Close
              </Button>
            </Box>
          </Box>
        </Stack>
      </Box>
    </Modal>
  );
};

export default SeqImporter;
